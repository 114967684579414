import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Story } from '@shared/models/members-area/story.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { StoryBE } from '@shared/models/members-area/story-be.model';
import { HttpListResponse } from '@ess-front/shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  private readonly apiURL = `${environment.apiEnv}api/`;
  private readonly STORIES_LIMIT = 12;

  constructor(private httpClient: HttpClient) {}

  getStories$(): Observable<Story[]> {
    const url = `${this.apiURL}stories/?limit=${this.STORIES_LIMIT}`;
    return this.httpClient.get<HttpListResponse<StoryBE>>(url).pipe(
      map(response => {
        return response.results.map(story => this.transformIntoStory(story));
      }),
    );
  }

  private transformIntoStory(story: StoryBE): Story {
    return {
      image: story.image,
      link: story.link,
    };
  }
}
