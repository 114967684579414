export interface TripStatus {
  label: string;
  value: TripStatusValue;
}

export enum TripStatusValue {
  BUILDING = 'building',
  CONCLUDED = 'concluded',
  FUTURE = '',
  PLANNING = 'planning',
  READY = 'ready',
}
