import { Routes } from '@angular/router';
import { RedirectGuard } from '@app/core/redirect/redirect.guard';
import { authGuard, UserRole } from '@ess-front/shared';
import { OnboardingSurveyGuard } from '@app/core/guards/onboarding-survey.guard';
import { UserTranslationResolver } from '@app/core/resolvers/user-translation.resolver';
import { TripTranslationResolver } from '@app/core/resolvers/trip-translation.resolver';
import { DefaultTranslationResolver } from '@app/core/resolvers/default-translation.resolver';
import { ParamTranslationResolver } from '@app/core/resolvers/param-translation-resolver.service';
import { UserHashTranslationResolver } from '@app/core/resolvers/user-hash-translation.resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'members-area',
    pathMatch: 'full',
  },
  {
    path: 'members-area/login',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('@app/legacy/features/layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'apply/amex',
    title: 'Amex - Apply Form',
    loadChildren: () =>
      import('src/app/features/apply-form-amex/apply-form-amex.module').then(m => m.ApplyFormAmexModule),
  },
  {
    path: 'opt-out',
    title: 'Amex - Access Removal',
    loadChildren: () => import('src/app/features/amex-opt-out/amex-opt-out.module').then(m => m.AmexOptOutModule),
  },
  {
    path: 'magic-link',
    resolve: {
      language: DefaultTranslationResolver,
    },
    loadComponent: () => import('@features/magic-link/magic-link.component').then(x => x.MagicLinkComponent),
  },
  {
    path: 'payments/:paymentResult',
    loadComponent: () =>
      import('./features/payments-message/payments-messages.component').then(m => m.PaymentsMessagesComponent),
    resolve: {
      language: DefaultTranslationResolver,
    },
  },
  {
    path: 'preferences-form/:hash',
    loadComponent: () =>
      import('src/app/features/preferences-form/preferences-form.component').then(x => x.PreferencesFormComponent),
    resolve: {
      language: UserHashTranslationResolver,
    },
  },
  {
    path: 'preferences-form-completed',
    loadComponent: () =>
      import('src/app/features/preferences-form-completed/preferences-form-completed.component').then(
        x => x.PreferencesFormCompletedComponent,
      ),
  },
  {
    path: 'trip/:hash/confirmation/:id',
    loadChildren: () => import('./shared/confirmation/confirmation.module').then(m => m.ConfirmationModule),
    data: {
      type: 'bookingexperience',
    },
    resolve: {
      language: TripTranslationResolver,
    },
  },
  // EXPERIENCE - DEEPLINK - REDIRECTS
  {
    path: 'hotels/:slug',
    redirectTo: 'hotels/:slug/en',
    pathMatch: 'full',
  },
  {
    path: 'experiences/:slug',
    redirectTo: 'experiences/:slug/en',
    pathMatch: 'full',
  },
  {
    path: 'restaurants/:slug',
    redirectTo: 'restaurants/:slug/en',
    pathMatch: 'full',
  },
  {
    path: 'places/:slug',
    redirectTo: 'places/:slug/en',
    pathMatch: 'full',
  },
  // EXPERIENCE - DEEPLINK
  {
    path: 'hotels/:slug/:lang',
    loadChildren: () => import('@features/experience/experience.module').then(m => m.ExperienceModule),
    data: {
      endpoint: 'hotel',
    },
    resolve: {
      language: ParamTranslationResolver,
    },
  },
  {
    path: 'experiences/:slug/:lang',
    loadChildren: () => import('@features/experience/experience.module').then(m => m.ExperienceModule),
    data: {
      endpoint: 'experience',
    },
    resolve: {
      language: ParamTranslationResolver,
    },
  },
  {
    path: 'restaurants/:slug/:lang',
    loadChildren: () => import('@features/experience/experience.module').then(m => m.ExperienceModule),
    data: {
      endpoint: 'restaurant',
    },
    resolve: {
      language: ParamTranslationResolver,
    },
  },
  {
    path: 'places/:slug/:lang',
    loadChildren: () => import('@features/experience/experience.module').then(m => m.ExperienceModule),
    data: {
      endpoint: 'place',
    },
    resolve: {
      language: ParamTranslationResolver,
    },
  },
  //BOOKING EXPERIENCE
  {
    path: 'trip/:hash/experience/:id',
    loadChildren: () => import('@features/experience/experience.module').then(m => m.ExperienceModule),
    data: {
      endpoint: 'bookingexperience',
    },
    resolve: {
      language: TripTranslationResolver,
    },
  },
  // NEW ITINERARY
  {
    path: 'itinerary/:hash',
    loadChildren: () => import('@features/itinerary/main/itinerary.module').then(m => m.ItineraryModule),
    resolve: {
      language: TripTranslationResolver,
    },
  },
  {
    path: 'trip/:hash',
    loadChildren: () => import('@features/itinerary/main/itinerary.module').then(m => m.ItineraryModule),
    resolve: {
      language: TripTranslationResolver,
    },
  },
  { path: 'trip-itinerary/:hash', redirectTo: 'itinerary/:hash', pathMatch: 'full' },
  {
    path: 'survey/:hash',
    loadChildren: () =>
      import('@features/satisfaction-survey/satisfaction-survey.module').then(m => m.SatisfactionSurveyModule),
    resolve: {
      language: TripTranslationResolver,
    },
  },
  {
    path: 'forbidden',
    loadComponent: () => import('./features/forbidden/forbidden.component').then(x => x.ForbiddenComponent),
  },
  {
    path: 'members-area',
    canActivate: [authGuard(UserRole.MEMBER, 'login'), OnboardingSurveyGuard],
    loadChildren: () => import('./features/members-area/members-area.module').then(m => m.MembersAreaModule),
    resolve: {
      language: UserTranslationResolver,
    },
  },
  {
    path: 'login',
    loadComponent: () => import('./features/auth/login/login.component').then(x => x.LoginComponent),
    resolve: {
      language: DefaultTranslationResolver,
    },
  },
  {
    path: 'reset-password/:token/:uid',
    loadComponent: () =>
      import('./features/auth/reset-password/reset-password.component').then(x => x.ResetPasswordComponent),
    resolve: {
      language: DefaultTranslationResolver,
    },
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./features/auth/forgot-password/forgot-password.component').then(x => x.ForgotPasswordComponent),
    resolve: {
      language: DefaultTranslationResolver,
    },
  },
  {
    path: 'authorization-form/:hash',
    loadComponent: () =>
      import('./features/authorization-form/authorization-form.component').then(x => x.AuthorizationFormComponent),
    resolve: {
      language: UserHashTranslationResolver,
    },
  },
  {
    path: 'onboarding-survey',
    canActivate: [OnboardingSurveyGuard],
    loadComponent: () =>
      import('./features/onboarding-survey/onboarding-survey.component').then(x => x.OnboardingSurveyComponent),
    resolve: {
      language: UserTranslationResolver,
    },
  },
  {
    canActivate: [RedirectGuard],
    path: '**',
    loadComponent: () => import('./features/not-found/not-found.component').then(x => x.NotFoundComponent),
  },
];
