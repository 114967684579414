import { BehaviorSubject, map, Observable } from 'rxjs';
import { BookingService } from '@app/api/booking.service';
import { StoryService } from '@app/api/story.service';
import { BookingSimple } from '@shared/models/members-area/booking-simple.model';
import { Story } from '@shared/models/members-area/story.model';
import { TripStatusValue } from '@shared/models/trip-status.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MemberDomainService {
  private tripsSubject$ = new BehaviorSubject<BookingSimple[]>([]);

  readonly futureTrips$ = this.tripsSubject$
    .asObservable()
    .pipe(map(bookings => bookings.filter(booking => this.isFutureTrip(booking)).reverse()));

  readonly pastTrips$ = this.tripsSubject$.asObservable().pipe(
    map(bookings => {
      return bookings.filter(booking => this.isPastTrip(booking));
    }),
  );

  readonly upcomingTrips$ = this.tripsSubject$.asObservable().pipe(
    map(bookings => {
      return bookings.filter(booking => this.isUpcomingTrip(booking)).reverse();
    }),
  );

  constructor(
    private bookingService: BookingService,
    private storyService: StoryService,
  ) {}

  getStories$(): Observable<Story[]> {
    return this.storyService.getStories$();
  }

  unloadTrips(): void {
    this.tripsSubject$.next([]);
  }

  loadTrips(): void {
    this.bookingService.getTrips$().subscribe(trips => {
      this.tripsSubject$.next(trips);
    });
  }

  private isPastTrip(booking: BookingSimple): boolean {
    return (
      !!booking.endDate &&
      new Date() > new Date(booking.endDate) &&
      booking.publicStatus.value !== TripStatusValue.FUTURE
    );
  }

  private isUpcomingTrip(booking: BookingSimple): boolean {
    return (
      !!booking.endDate &&
      new Date() <= new Date(booking.endDate) &&
      booking.publicStatus.value !== TripStatusValue.FUTURE
    );
  }

  private isFutureTrip(booking: BookingSimple): boolean {
    return booking.publicStatus.value === TripStatusValue.FUTURE;
  }
}
