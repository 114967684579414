import { Injectable } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslationDomainService } from '@domain/translation-domain.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomDatepickerIntl extends MatDatepickerIntl {
  constructor(private translationDomainService: TranslationDomainService) {
    super();
    this.translationDomainService.langChange$
      .pipe(
        switchMap(() => this.translationDomainService.isTranslationLoaded$()),
        tap(() => this.setTranslations()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  private setTranslations() {
    this.calendarLabel = this.translationDomainService.translate(marker('datepicker.calendarLabel'));
    this.closeCalendarLabel = this.translationDomainService.translate(marker('datepicker.closeCalendarLabel'));
    this.nextMonthLabel = this.translationDomainService.translate(marker('datepicker.nextMonthLabel'));
    this.nextMultiYearLabel = this.translationDomainService.translate(marker('datepicker.nextMultiYearLabel'));
    this.nextYearLabel = this.translationDomainService.translate(marker('datepicker.nextYearLabel'));
    this.openCalendarLabel = this.translationDomainService.translate(marker('datepicker.openCalendarLabel'));
    this.prevMonthLabel = this.translationDomainService.translate(marker('datepicker.prevMonthLabel'));
    this.prevMultiYearLabel = this.translationDomainService.translate(marker('datepicker.prevMultiYearLabel'));
    this.prevYearLabel = this.translationDomainService.translate(marker('datepicker.prevYearLabel'));
    this.switchToMonthViewLabel = this.translationDomainService.translate(marker('datepicker.switchToMonthViewLabel'));
    this.switchToMultiYearViewLabel = this.translationDomainService.translate(
      marker('datepicker.switchToMultiYearViewLabel'),
    );
  }
}
