import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService, AuthStorageService, ServerActionsStore } from '@ess-front/shared';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { map, Observable, of } from 'rxjs';
import { UserDomainService } from './user-domain.service';
import { MemberDomainService } from './member-domain.service';

@Injectable({
  providedIn: 'root',
})
export class AuthDomainService {
  constructor(
    private authService: AuthService,
    private authStorageService: AuthStorageService,
    private matDialog: MatDialog,
    private serverActionsStore: ServerActionsStore,
    private userDomainService: UserDomainService,
    private memberDomainService: MemberDomainService,
  ) {}

  isLoggedIn$(): Observable<boolean> {
    return this.userDomainService.getCurrentUser$().pipe(map(user => !!user));
  }

  login(email: string, password: string, keepLogged: boolean, isModal: boolean) {
    return this.authService.login(email, password, keepLogged, isModal ? null : '/members-area');
  }

  logout(redirectUrl?: string) {
    this.serverActionsStore.disconnect();
    this.userDomainService.clearCurrentUser();
    this.memberDomainService.unloadTrips();
    this.authService.logout(redirectUrl || null);
  }

  loggedIn(): boolean {
    return !!this.authStorageService.getToken();
  }

  handleActionWithAuthentication$(): Observable<boolean> {
    if (this.loggedIn()) {
      return of(true);
    } else {
      const dialogRef = this.openLoginModal();
      return dialogRef.afterClosed();
    }
  }

  private openLoginModal(): MatDialogRef<LoginModalComponent> {
    return this.matDialog.open(LoginModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      width: '100vw',
      autoFocus: false,
    });
  }
}
