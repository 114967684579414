import { LoadingScreenComponent } from '@shared/loading-screen/loading-screen.component';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { LoadingModule } from './core/loading/loading.module';
import { RouterOutlet } from '@angular/router';
import { Component, inject, OnInit } from '@angular/core';
import { enableZoomWebView } from './utils/webview/webview.util';
import { release } from '@release';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'ess-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, LoadingModule, LoadingScreenComponent],
})
export class AppComponent implements OnInit {
  loading = true;
  private readonly angulartics2GoogleTagManager = inject(Angulartics2GoogleTagManager);

  constructor(private overlayContainer: OverlayContainer) {
    /* eslint-disable-next-line no-console */
    console.log(` ℹ️ Release: %c${release}`, 'color: blue');
  }
  ngOnInit() {
    enableZoomWebView();
    this.angulartics2GoogleTagManager.startTracking();
    this.overlayContainer.getContainerElement().setAttribute('role', 'region');
  }
}
